import React, { useEffect, useState } from "react"

import { Button, Card, Col, Form, Input, message, Popover, Row, Select, Tooltip } from "antd"
import { trans } from "@resources/localization"
import SecurityService from "@util/SecurityService"
import { CONTACT_SCOPE } from "@config/permission"

import { useAppSelector } from "@hook/useAppSelector"
import { isEmpty, map } from "lodash"
import { formatDateFull } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callAssignContactToTelesales, callGetContactDetail, callResetAssignment, callUpdateInfoContact } from "@reducer/version2/contact-detail.reducer"
import { CALL_STATUS, STRINGEE_AUTH, VIETNAM_CODE } from "@config/constant"
import { Address } from "./Address"
import CallStringeeService from "@api/version2/StringeeApi"
import { ModalStringee } from "./ModalStringee"
import { setTokenStringee } from "@reducer/version2/call-stringee.reducer"
import { callGetTheSameTelesalesInGroups } from "@reducer/version2/telesales-group-detail.reducer"

declare const window: any

type Props = {
    setStatusShow: (data: any) => void
}

export const SummaryInfo: React.FC<Props> = ({setStatusShow}) => {
    const [formRef] = Form.useForm()
    const [formAssign] = Form.useForm()
    const [isUpdateContact, setIsUpdateContact] = useState<boolean>(false)
    const contactDetail = useAppSelector((state) => state.contactDetailVersion2.contactDetail)
    const loadingUpdate = useAppSelector((state) => state.contactDetailVersion2.loadingUpdate)
    const adsOfContact = useAppSelector((state) => state.contactDetailVersion2.adsOfContact)
    const dispatch = useAppDispatch()
    const userInfo = SecurityService.getUser()
    const [isCalling, setIsCalling] = useState(false)
    const [callState, setCallState] = useState("")
    const [isAnswerPhone, setIsAnswerPhone] = useState(false)
    const [countTime, setCountTime] = useState(0)
    const [isStringeeAuthen, setIsStringeeAuthen] = useState(false)
    const [isMute, setIsMute] = useState(false)
    const [intervalId, setIntervalId] = useState<any>(null)
    const areas = useAppSelector((state) => state.countriesVersion2.areasSuggest)
    const loadingCancel = useAppSelector((state) => state.contactDetailVersion2.loadingCancelAssignment)
    const groupTelesalesProduct = useAppSelector((state) => state.productDetail.groupTelesalesProduct)
    const theSameTelesales = useAppSelector((state) => state.telesalesGroupDetail.telesalesInGroup)
    const loadingAssign = useAppSelector((state) => state.contactVersion2.loadingAssign)
    const [openAssign, setOpenAssign] = useState(false)

    useEffect(() => {
        if (!isEmpty(contactDetail)) {
            connectStringee()
            formAssign.setFieldsValue({
                assignee: contactDetail?.assignee || null,
            })
        }
    }, [contactDetail])

    const handleToggleButtonUpdate = () => {
        setIsUpdateContact(!isUpdateContact)
        formRef.setFieldsValue({
            name: contactDetail?.name || "",
            note: contactDetail?.note || "",
            province: contactDetail?.province?.name,
            city: contactDetail?.city?.name,
            district: contactDetail?.district?.name,
            ward: contactDetail?.ward,
            address: contactDetail?.address,
        })
    }

    const onUpdateContact = async (values: any) => {
        dispatch(
            callUpdateInfoContact({
                contactId: contactDetail?.id || "",
                body: {
                    ...values,
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                dispatch(callGetContactDetail(contactDetail?.id || "")).then((result: any) => {
                    setStatusShow(result?.payload?.response?.status)
                })
                setIsUpdateContact(!isUpdateContact)
            } else message.error(result?.payload?.response?.data?.message || trans("message.fail"))
        })
    }

    const getExtraCard = () => {
        if (isUpdateContact) {
            return (
                <>
                    <Button
                        onClick={() => formRef.submit()}
                        loading={loadingUpdate}
                        type={"primary"}
                        className={"mg-r-5 _btn_submit_form_contact"}
                        htmlType={"submit"}>
                        {trans("button.update")}
                    </Button>
                    <Button
                        onClick={handleToggleButtonUpdate}
                        loading={loadingUpdate}
                        className={"_btn_cancel_form_contact"}>
                        {trans("button.cancel")}
                    </Button>
                </>
            )
        }

        return (
            SecurityService.can(CONTACT_SCOPE.CONTACT_DETAIL_UPDATE) && (
                <Button
                    type="default"
                    icon={
                        <i
                            className="fa fa-pencil mr-2"
                            aria-hidden="true"></i>
                    }
                    className={"_btn_show_edit_form_contact"}
                    onClick={handleToggleButtonUpdate}>
                    {trans("button.edit")}
                </Button>
            )
        )
    }
    const handleCancelAssignment = () => {
        dispatch(callResetAssignment(contactDetail?.id || "")).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                dispatch(callGetContactDetail(contactDetail?.id || "")).then((result: any) => {
                    setStatusShow(result?.payload?.response?.status)
                })
            } else message.error(result?.payload?.response?.data?.message || trans("message.fail"))
        })
    }

    const getDivShowInfoContact = (keyTrans: string, data: string, type?: string) => {
        return (
            <>
                <div className="flex items-center">
                    <p className="font-medium m-0">{trans(keyTrans)}: </p>
                    <p className={`m-0 ml-1 ${type === "assign" && SecurityService.can(CONTACT_SCOPE.CONTACT_ASSIGN_TO) ? "text-blue-400" : ""}`}>{data}</p>
                    {type === "phone" && isStringeeAuthen && SecurityService.can(CONTACT_SCOPE.CONTACT_CALL_STRINGEE) && (
                        <Button
                            onClick={() => handleCallStringee()}
                            type="link"
                            icon={<i className="fa-light fa-phone-flip"></i>}></Button>
                    )}
                    {type === "assign" && SecurityService.can(CONTACT_SCOPE.CONTACT_ASSIGN_TO) && (
                        <Popover
                            open={openAssign}
                            placement="right"
                            content={renderSelectAssign}
                            trigger="click">
                            <Tooltip title={trans("contact.assign_to_title")}>
                                <Button
                                    type="link"
                                    onClick={() => setOpenAssign((old) => !old)}
                                    icon={<i className="fa-sharp fa-regular fa-chevron-down"></i>}
                                />
                            </Tooltip>
                        </Popover>
                    )}
                    {type === "assign" && data && SecurityService.can(CONTACT_SCOPE.CONTACT_ASSIGN_RESET) && (
                        <Tooltip title={trans("contact.reset_assign")}>
                            <Button
                                onClick={() => handleCancelAssignment()}
                                type="link"
                                loading={loadingCancel}
                                icon={<i className="fa-regular fa-circle-xmark"></i>}
                            />
                        </Tooltip>
                    )}
                </div>
            </>
        )
    }
    const aissignToTelesales = (assignee: string) => {
        dispatch(
            callAssignContactToTelesales({
                code: contactDetail?.id || "",
                body: {
                    assignee,
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                setOpenAssign(false)
                dispatch(callGetContactDetail(contactDetail?.id || "")).then((result: any) => {
                    setStatusShow(result?.payload?.response?.status)
                })
            } else message.error(result?.payload?.response?.data?.message || trans("message.fail"))
        })
    }
    const renderSelectAssign = () => (
        <div className="w-48">
            <Form form={formAssign}>
                <Row>
                    <Col span={24}>
                        <Form.Item name="groupCode">
                            <Select
                                placeholder={trans("products.select_group")}
                                onChange={(value) => {
                                    formAssign.setFieldsValue({
                                        assignee: null,
                                    })
                                    if (value) {
                                        dispatch(callGetTheSameTelesalesInGroups(value))
                                    }
                                }}>
                                {groupTelesalesProduct?.map((item) => (
                                    <Select.Option
                                        key={item?.groupCode}
                                        value={item?.groupCode}>
                                        {item?.group?.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item shouldUpdate={(pre, next) => pre.groupCode !== next.groupCode}>
                            {({ getFieldValue }) => (
                                <Form.Item name="assignee">
                                    <Select
                                        loading={loadingAssign}
                                        placeholder={trans("contact.select_telesale")}
                                        disabled={!getFieldValue("groupCode")}
                                        onChange={(value) => aissignToTelesales(value)}>
                                        {theSameTelesales?.map((item) => (
                                            <Select.Option
                                                key={item?.telesaleUsername}
                                                value={item?.telesaleUsername}>
                                                {item?.telesaleUsername}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )

    const getRowInfoContact = () => {
        return (
            <div className="grid lg:grid-cols-2 md:grid-cols-1">
                {getDivShowInfoContact("contact-detail.fullname", contactDetail?.name || "")}
                {getDivShowInfoContact("contact-detail.phone", contactDetail?.phone || "", "phone")}
                {getDivShowInfoContact("contact.ads", map(adsOfContact, "name")?.join(",") || "")}
                {getDivShowInfoContact("contact.created_at", contactDetail?.createdAt ? formatDateFull(contactDetail?.createdAt) : "")}
                {getDivShowInfoContact("order_list.assign_to", contactDetail?.assignee || "", "assign")}
                {getDivShowInfoContact("contact.assigned_by", contactDetail?.assigner || "")}
                {getDivShowInfoContact("contact.assigned_at", contactDetail?.assignedAt ? formatDateFull(contactDetail?.assignedAt) : "")}
                {getDivShowInfoContact("contact-detail.last_contact", contactDetail?.lastCallAt ? formatDateFull(contactDetail?.lastCallAt) : "")}
                {getDivShowInfoContact("contact-detail.note", contactDetail?.note || "")}
                {getDivShowInfoContact("contact.country", contactDetail?.country?.name || "")}
                {getDivShowInfoContact("contact-detail.province", contactDetail?.province?.name || "")}
                {getDivShowInfoContact(
                    contactDetail?.countryCode === VIETNAM_CODE ? "contact-detail.district" : "order_list.district",
                    contactDetail?.city?.name || ""
                )}
                {getDivShowInfoContact(
                    contactDetail?.countryCode === VIETNAM_CODE ? "contact_activity.ward" : "contact-detail.district",
                    contactDetail?.district?.name || ""
                )}
                {getDivShowInfoContact("contact-detail.area", areas.find((el) => el.code === contactDetail?.ward)?.name || contactDetail?.ward || "")}
                {getDivShowInfoContact("contact-detail.address_detail", contactDetail?.address || "")}
            </div>
        )
    }

    const getFormUpdateContact = () => {
        return (
            <>
                <Form
                    form={formRef}
                    onFinish={onUpdateContact}
                    layout="horizontal"
                    labelAlign="left"
                    labelCol={{ xs: { span: 24 }, md: { span: 24 }, lg: { span: 8 } }}
                    wrapperCol={{ xs: { span: 24 }, md: { span: 24 }, lg: { span: 16 } }}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label={trans("contact-detail.fullname")}
                                rules={[{ required: true, message: trans("message.required") }]}>
                                <Input maxLength={160} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="note"
                                label={trans("contact-detail.note")}>
                                <Input.TextArea
                                    autoSize={{
                                        minRows: 4,
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Address formRef={formRef} />
                    </Row>
                </Form>
            </>
        )
    }

    const connectStringee = async () => {
        try {
            const stringeeAccessToken = await CallStringeeService.getAccessToken({
                from_alias: userInfo?.sub,
                tenant: contactDetail?.vendor,
            })
            window.client = new window.StringeeClient()
            if (stringeeAccessToken?.status === 200 && stringeeAccessToken?.data?.accessToken && !window.client.hasConnected) {
                settingClientEvents(window.client)
                window.client.connect(stringeeAccessToken?.data?.accessToken)
                dispatch(setTokenStringee(stringeeAccessToken?.data?.accessToken || ""))
                setIsStringeeAuthen(true)
                return
            }
            if (stringeeAccessToken?.status === 200 && window.client.hasConnected) {
                setIsStringeeAuthen(true)
                return
            }
            // if (stringeeAccessToken?.status !== 200) {
            //     throw new Error(stringeeAccessToken.message)
            // }
        } catch (error: any) {
            // message.error(error?.message)
            setIsStringeeAuthen(false)
        }
    }
    const settingClientEvents = (client: any) => {
        client.on("connect", () => {
            //
        })

        client.on("authen", (res: any) => {
            if (res.r === STRINGEE_AUTH.SUCCESS || res.r === STRINGEE_AUTH.IS_AUTH) {
                setIsStringeeAuthen(true)
            } else {
                setIsStringeeAuthen(false)
            }
        })

        client.on("requestnewtoken", async () => {
            //please get new access_token from YourServer and call:
            try {
                const stringeeAccessToken = await CallStringeeService.getAccessToken({
                    from_alias: userInfo?.sub,
                    tenant: contactDetail?.vendor,
                })
                if (stringeeAccessToken && stringeeAccessToken.code === 1) {
                    settingClientEvents(window.client)
                    window.client.connect(stringeeAccessToken.data.accessToken)
                    return
                }
                if (stringeeAccessToken.code === 1 && window.client.hasConnected) {
                    setIsStringeeAuthen(true)
                    return
                }
                // if (stringeeAccessToken.code !== 1) {
                //     throw new Error(stringeeAccessToken.message)
                // }
            } catch (error: any) {
                // message.error(error?.message)
                setIsStringeeAuthen(false)
            }
        })
    }
    const handleCallStringee = async () => {
        try {
            const listNumber = await CallStringeeService.getListNumber({
                tenant: contactDetail?.vendor,
            })
            if (listNumber?.data.length <= 0) {
                message.error(trans("stringee.not_found"))
                return
            }
            const hotLine = listNumber?.data[0].number.substring(1)
            window.call = new window.StringeeCall(window.client, hotLine, contactDetail?.nationalPhone)
            settingCallEvents(window.call)
            const customMake = JSON.stringify({
                tenant: contactDetail?.vendor,
                campaign: contactDetail?.campaign,
                from_alias: userInfo?.sub,
                to_alias: contactDetail?.nationalPhone,
            })
            window.call.custom = btoa(customMake)
            window.call.makeCall((res: any) => {
                if (res.r !== 0) {
                    message.error(res.message)
                }
            })
        } catch (error: any) {
            message.error(error?.message)
        }
    }
    const settingCallEvents = (call1: any) => {
        call1.on("error", (info: any) => {
            message.error(info.message)
        })

        call1.on("addlocalstream", (stream: any) => {
            // reset srcObject to work around minor bugs in Chrome and Edge.
            window.remoteVideo.srcObject = null
            window.remoteVideo.srcObject = stream
        })

        call1.on("addremotestream", (stream: any) => {
            // reset srcObject to work around minor bugs in Chrome and Edge.
            window.remoteVideo.srcObject = null
            window.remoteVideo.srcObject = stream
        })

        call1.on("signalingstate", (state: any) => {
            setIsCalling(true)
            setCallState(state?.reason)
            if (state.code === CALL_STATUS.BUSY || state.code === CALL_STATUS.ENDED) {
                setIsAnswerPhone(false)
                clearInterval(intervalId)
                setCountTime(0)
            }

            if (state.code === CALL_STATUS.ANSWERED) {
                const countTimeInterval = setInterval(() => {
                    setCountTime((old) => old + 1)
                }, 1000)
                setIntervalId(countTimeInterval)
                setIsAnswerPhone(true)
            }
        })
        call1.on("mediastate", () => {
            //
        })
    }
    const handleMuteCall = () => {
        window.call.mute(!isMute)
        setIsMute((old) => !old)
    }
    const handleModalClose = () => {
        window.call.hangup(() => {
            setCallState("")
        })
        setIsCalling(false)
        setIsAnswerPhone(false)
        clearInterval(intervalId)
        setCountTime(0)
    }
    return (
        <>
            <Card
                title={trans("contact-detail.key_field")}
                extra={getExtraCard()}>
                {isUpdateContact ? getFormUpdateContact() : getRowInfoContact()}
                {isCalling && (
                    <ModalStringee
                        isCalling={isCalling}
                        closeModal={() => handleModalClose()}
                        callState={callState}
                        phone={contactDetail?.nationalPhone}
                        isAnswerPhone={isAnswerPhone}
                        countTime={countTime}
                        handleMuteCall={handleMuteCall}
                        isMute={isMute}
                    />
                )}
            </Card>
        </>
    )
}
