import React, { useEffect } from "react"
import { trans } from "@resources/localization"
import { Button, Card, Col, Form, Input, message, Row, Select, Space, Empty, Typography } from "antd"
import { useAppSelector } from "@hook/useAppSelector"
import SecurityService from "@util/SecurityService"
import { ORDER_SCOPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeEditShippingAddress, fetchUpdateOrder, getOrderDetail } from "@reducer/version2/order-detail.reducer"
import { IOrderRequest } from "@domain/version2/OrderDetail"
import { find, isEmpty } from "lodash"
import { getAreaSuggest, getCitySuggest, getDistrictSuggest, resetDataArea, resetDataCity, resetDataDistrict } from "@reducer/version2/countries.reducer"
import { STATUS_CAN_UPDATE, VIETNAM_CODE } from "@config/constant"
import { numberParser } from "@util/Common"

export const ShippingAddress = () => {
    const [formRef] = Form.useForm()
    const detailOrder = useAppSelector((state) => state.orderDetailVersion2.orderDetail)
    const isUpdateShippingAddress = useAppSelector((state) => state.orderDetailVersion2.isUpdateShippingAddress)
    const loadingUpdate = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrder)
    const couriersSuggest = useAppSelector((state) => state.countriesVersion2.couriersSuggest)
    const provinces = useAppSelector((state) => state.countriesVersion2.provincesSuggest)
    const cities = useAppSelector((state) => state.countriesVersion2.citiesSuggest)
    const districts = useAppSelector((state) => state.countriesVersion2?.districtsSuggest)
    const areas = useAppSelector((state) => state.countriesVersion2?.areasSuggest)

    const dispatch = useAppDispatch()
    useEffect(() => {
        formRef.setFieldsValue({
            customerName: detailOrder?.customerName,
            customerPhone: detailOrder?.customerPhone,
            courierService: detailOrder?.courierServiceCode,
            province: detailOrder?.provinceCode,
            district: detailOrder?.districtCode,
            ward: detailOrder?.ward,
            address: detailOrder?.address,
            postalCode: detailOrder?.postalCode,
        })
    }, [detailOrder])

    const handleSubmit = (values: IOrderRequest) => {
        dispatch(
            fetchUpdateOrder({
                code: detailOrder?.code || "",
                body: {
                    ...values,
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                dispatch(getOrderDetail(detailOrder?.code || ""))
                dispatch(changeEditShippingAddress(false))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    const onSetInitialForm = () => {
        formRef.setFieldsValue({
            customerName: detailOrder?.customerName,
            customerPhone: detailOrder?.customerPhone,
            courierService: detailOrder?.courierServiceCode,
            province: detailOrder?.province?.code,
            city: detailOrder?.city?.code,
            district: detailOrder?.district?.code,
            ward: detailOrder?.ward,
            address: detailOrder?.address,
            postalCode: detailOrder?.postalCode,
        })
    }

    const renderDetailShipping = (name: string, value: string) => (
        <>
            <Col span={isUpdateShippingAddress ? 8 : 12}>
                <Typography.Text className={isUpdateShippingAddress ? "ml-2" : "font-medium"}>{name}</Typography.Text>
            </Col>
            <Col
                span={isUpdateShippingAddress ? 16 : 12}
                className={isUpdateShippingAddress ? "mb-3" : ""}>
                {value}
            </Col>
        </>
    )
    const renderTextMode = () => {
        return (
            <Form
                form={formRef}
                onFinish={handleSubmit}
                layout="horizontal"
                labelAlign="left"
                labelCol={{ xs: { span: 24 }, md: { span: 24 }, lg: { span: 8 } }}
                wrapperCol={{ xs: { span: 24 }, md: { span: 24 }, lg: { span: 16 } }}>
                <Row gutter={!isUpdateShippingAddress ? [0, 15] : [0, 5]}>
                    {isUpdateShippingAddress ? (
                        <Col span={24}>
                            <Form.Item
                                name="customerName"
                                label={trans("order_detail.fullname")}
                                rules={[{ required: true, message: trans("message.required") }]}>
                                <Input
                                    maxLength={32}
                                    onBlur={(e) => {
                                        formRef.setFieldsValue({
                                            customerName: e.target.value.trim(),
                                        })
                                    }}
                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        renderDetailShipping(trans("order_detail.fullname"), detailOrder?.customerName || "")
                    )}
                    {isUpdateShippingAddress ? (
                        <Col span={24}>
                            <Form.Item
                                name="customerPhone"
                                label={trans("order_detail.phone")}
                                rules={[{ required: true, message: trans("message.required") }]}>
                                <Input
                                    onChange={(e) => {
                                        formRef.setFieldsValue({
                                            customerPhone: e.target.value?.replace(/\D/g, ""),
                                        })
                                    }}
                                    maxLength={15}
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        renderDetailShipping(trans("order_detail.phone"), detailOrder?.customerPhone || "")
                    )}
                    {isUpdateShippingAddress ? (
                        <Col span={24}>
                            <Form.Item
                                name="courierService"
                                label={trans("order_detail.courier")}
                                rules={[{ required: true, message: trans("message.required") }]}>
                                <Select>
                                    {couriersSuggest?.map((item) => (
                                        <Select.Option
                                            key={item?.courierCode}
                                            value={item?.courierCode}>
                                            {item?.courierName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : (
                        renderDetailShipping(
                            trans("order_detail.courier"),
                            find(couriersSuggest, { courierCode: detailOrder?.courierServiceCode })?.courierName || ""
                        )
                    )}
                    {renderDetailShipping(trans("vendor.country"), detailOrder?.country?.name || "")}
                    {isUpdateShippingAddress ? (
                        <Col span={24}>
                            <Form.Item
                                label={trans("order.postal_code")}
                                name="postalCode"
                                rules={
                                    detailOrder?.country?.code === "MYS"
                                        ? [
                                              {
                                                  required: true,
                                                  message: trans("message.required"),
                                              },
                                          ]
                                        : []
                                }>
                                <Input
                                    onChange={(e) => {
                                        formRef.setFieldsValue({
                                            postalCode: e?.target?.value ? numberParser(e?.target?.value) : "",
                                        })
                                    }}
                                    maxLength={detailOrder?.country?.code === "MYS" ? 5 : 20}
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        renderDetailShipping(trans("order.postal_code"), detailOrder?.postalCode || "")
                    )}
                    {isUpdateShippingAddress ? (
                        <Col span={24}>
                            <Form.Item
                                label={trans("contact-detail.province")}
                                name="province"
                                rules={[
                                    {
                                        required: true,
                                        message: trans("message.required"),
                                    },
                                ]}>
                                <Select
                                    placeholder={trans("order_list.district_placeholder")}
                                    showSearch
                                    optionFilterProp="children"
                                    notFoundContent={<Empty />}
                                    filterOption={(input: any, option: any) => {
                                        return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                    }}
                                    filterSort={(optionA: any, optionB: any) => {
                                        return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                    }}
                                    onChange={(e) => {
                                        dispatch(resetDataDistrict([]))
                                        dispatch(resetDataCity([]))
                                        dispatch(resetDataArea([]))
                                        formRef.setFieldsValue({
                                            city: "",
                                            district: "",
                                            ward: "",
                                        })
                                        if (e) {
                                            dispatch(getCitySuggest(e))
                                        }
                                    }}>
                                    {provinces?.map((item) => (
                                        <Select.Option
                                            key={item?.id}
                                            value={item?.code}>
                                            {item?.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    ) : (
                        renderDetailShipping(trans("order_detail.city"), detailOrder?.province?.name || "")
                    )}
                    {isUpdateShippingAddress ? (
                        <Col span={24}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(pre, next) => pre.province !== next.province}>
                                {({ getFieldValue }) => (
                                    <Form.Item
                                        label={detailOrder?.countryCode === VIETNAM_CODE ? trans("contact-detail.district") : trans("order_list.district")}
                                        name="city"
                                        rules={[
                                            {
                                                required: true,
                                                message: trans("message.required"),
                                            },
                                        ]}>
                                        <Select
                                            placeholder={
                                                detailOrder?.countryCode === VIETNAM_CODE
                                                    ? trans("contact-detail.district_pl")
                                                    : trans("order_list.city_placeholder")
                                            }
                                            showSearch
                                            notFoundContent={<Empty />}
                                            disabled={!getFieldValue("province")}
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) => {
                                                return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                            }}
                                            filterSort={(optionA: any, optionB: any) => {
                                                return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                            }}
                                            onChange={(e) => {
                                                dispatch(resetDataArea([]))
                                                dispatch(resetDataDistrict([]))
                                                formRef.setFieldsValue({
                                                    district: "",
                                                    ward: "",
                                                })
                                                if (e) {
                                                    dispatch(getDistrictSuggest(e))
                                                }
                                            }}>
                                            {cities?.map((item) => (
                                                <Select.Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                    ) : (
                        renderDetailShipping(
                            detailOrder?.countryCode === VIETNAM_CODE ? trans("order_detail.district") : trans("order_list.district"),
                            detailOrder?.city?.name || ""
                        )
                    )}
                    {isUpdateShippingAddress ? (
                        <Col span={24}>
                            <Form.Item
                                noStyle
                                shouldUpdate={(pre, next) => pre.city !== next.city}>
                                {({ getFieldValue }) => (
                                    <Form.Item
                                        label={detailOrder?.countryCode === VIETNAM_CODE ? trans("contact_activity.ward") : trans("contact-detail.district")}
                                        name="district"
                                        rules={
                                            !isEmpty(districts)
                                                ? [
                                                      {
                                                          required: true,
                                                          message: trans("message.required"),
                                                      },
                                                  ]
                                                : []
                                        }>
                                        <Select
                                            placeholder={
                                                detailOrder?.countryCode === VIETNAM_CODE
                                                    ? trans("order_list.ward_placeholder")
                                                    : trans("contact-detail.district_pl")
                                            }
                                            showSearch
                                            notFoundContent={<Empty />}
                                            disabled={!getFieldValue("city")}
                                            optionFilterProp="children"
                                            filterOption={(input: any, option: any) => {
                                                return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                            }}
                                            filterSort={(optionA: any, optionB: any) => {
                                                return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                            }}
                                            onChange={(e) => {
                                                dispatch(resetDataArea([]))
                                                formRef.setFieldsValue({
                                                    ward: "",
                                                })
                                                if (e) {
                                                    dispatch(getAreaSuggest(e))
                                                }
                                            }}>
                                            {districts?.map((item) => (
                                                <Select.Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                            </Form.Item>
                        </Col>
                    ) : (
                        renderDetailShipping(
                            detailOrder?.countryCode === VIETNAM_CODE ? trans("contact_activity.ward") : trans("order_detail.district"),
                            detailOrder?.district?.name || ""
                        )
                    )}
                    {isUpdateShippingAddress ? (
                        <Col span={24}>
                            {!isEmpty(areas) ? (
                                <Col span={24}>
                                    <Form.Item
                                        label={trans("contact-detail.area")}
                                        name="ward">
                                        <Select
                                            placeholder={trans("placeholder.select_area")}
                                            showSearch
                                            optionFilterProp="children"
                                            notFoundContent={<Empty />}
                                            filterOption={(input: any, option: any) => {
                                                return (option?.children ?? "")?.toLowerCase().includes(input?.toLowerCase())
                                            }}
                                            filterSort={(optionA: any, optionB: any) => {
                                                return (optionA?.children ?? "").toLowerCase().localeCompare((optionB?.children ?? "").toLowerCase())
                                            }}>
                                            {areas?.map((item) => (
                                                <Select.Option
                                                    key={item?.id}
                                                    value={item?.code}>
                                                    {item?.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            ) : (
                                <Col span={24}>
                                    <Form.Item
                                        label={trans("contact-detail.area")}
                                        name="ward">
                                        <Input
                                            placeholder={trans("contact-detail.area")}
                                            onBlur={(e) => {
                                                formRef.setFieldsValue({
                                                    ward: e.target.value.trim(),
                                                })
                                            }}
                                            onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                        </Col>
                    ) : (
                        renderDetailShipping(trans("contact-detail.area"), find(areas, { code: detailOrder?.ward })?.name || detailOrder?.ward || "")
                    )}
                    {isUpdateShippingAddress ? (
                        <Col span={24}>
                            <Form.Item
                                label={trans("contact-detail.address_detail")}
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: trans("message.required"),
                                    },
                                ]}>
                                <Input.TextArea
                                    rows={4}
                                    placeholder={trans("contact-detail.address_pl")}
                                    onBlur={(e) => {
                                        formRef.setFieldsValue({
                                            address: e.target.value.trim(),
                                        })
                                    }}
                                    onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
                                />
                            </Form.Item>
                        </Col>
                    ) : (
                        renderDetailShipping(trans("contact-detail.address_detail"), detailOrder?.address || "")
                    )}
                </Row>
            </Form>
        )
    }
    const renderButton = () => {
        if (isUpdateShippingAddress) {
            return (
                <Space>
                    <Button
                        type="primary"
                        loading={loadingUpdate}
                        onClick={() => {
                            formRef.submit()
                        }}>
                        {trans("button.update")}
                    </Button>
                    <Button
                        type="default"
                        loading={loadingUpdate}
                        onClick={() => {
                            formRef.resetFields()
                            dispatch(changeEditShippingAddress(false))
                        }}>
                        {trans("button.cancel")}
                    </Button>
                </Space>
            )
        }
        return (
            STATUS_CAN_UPDATE.includes(detailOrder?.status || "") &&
            SecurityService.can(ORDER_SCOPE.ORDER_UPDATE_ADDRESS) && (
                <Button
                    type="default"
                    icon={
                        <i
                            className="fa fa-pencil mr-2"
                            aria-hidden="true"></i>
                    }
                    onClick={() => {
                        onSetInitialForm()
                        dispatch(changeEditShippingAddress(true))
                    }}
                    className="_btn_toggle_edit_shipping_address">
                    {trans("button.edit")}
                </Button>
            )
        )
    }
    return (
        <div className="mt-3">
            <Card
                extra={renderButton()}
                title={trans("order_detail.shipping_address")}>
                <div className="shipping-address-form">{renderTextMode()}</div>
            </Card>
        </div>
    )
}
