import CategoriesApi from "@api/version2/Categories"
import { ICallStatus, IComboCategories, IContactStatus, IDeliveryCaseReason, IDeliveryCaseStatus, IEventStatus } from "@domain/version2/Categories"
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit"
import { notification } from "antd"

interface State {
    contactStatus: Array<IContactStatus>
    callStatus: Array<ICallStatus>
    contactEventStatus: IEventStatus[]
    orderStatuses: any[]
    subOrderStatuses: any[]
    deliveryCaseStatus: IDeliveryCaseStatus[]
    deliveryCaseReason: IDeliveryCaseReason[]
    comboCategories: IComboCategories[]
}

const initState: State = {
    contactStatus: [],
    callStatus: [],
    contactEventStatus: [],
    orderStatuses: [],
    subOrderStatuses: [],
    deliveryCaseStatus: [],
    deliveryCaseReason: [],
    comboCategories: [],
}

export const callGetContactStatus = createAsyncThunk("CATEGORIES_GET_CONTACT_STATUS", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getContactStatus()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetCallStatus = createAsyncThunk("CATEGORIES_GET_CALL_STATUS", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getCallStatus()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetContactEventStatus = createAsyncThunk("CATEGORIES_GET_CONTACT_EVENT_STATUS", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getContactStatusEvent()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetOrderStatus = createAsyncThunk("CATEGORIES_GET_ORDER_STATUS", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getOrderStatus()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetSubOrderStatus = createAsyncThunk("CATEGORIES_GET_SUB_ORDER_STATUS", async (parentCode: string, thunkApi) => {
    try {
        return await CategoriesApi.getSubOrderStatus(parentCode)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetDeliveryCaseStatus = createAsyncThunk("CATEGORIES_GET_DELIVERY_CASE_STATUS", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getDeliveryCaseStatus()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetDeliveryCaseReason = createAsyncThunk("CATEGORIES_GET_DELIVERY_CASE_REASON", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getDeliveryCaseReason()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})
export const callGetComboCategories = createAsyncThunk("CATEGORIES_COMBO", async (_, thunkApi) => {
    try {
        return await CategoriesApi.getComboCategories()
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const categoriesReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetContactStatus.pending, (state) => {
            state.contactStatus = []
        })
        .addCase(callGetContactStatus.fulfilled, (state, { payload }) => {
            state.contactStatus = payload.data
        })
        .addCase(callGetContactStatus.rejected, (state, action: any) => {
            state.contactStatus = []
            notification.error({
                message: action?.payload?.response?.data?.message || "ERROR",
            })
        })

    builder
        .addCase(callGetCallStatus.pending, (state) => {
            state.contactStatus = []
        })
        .addCase(callGetCallStatus.fulfilled, (state, { payload }) => {
            state.callStatus = payload.data
        })
        .addCase(callGetCallStatus.rejected, (state, action: any) => {
            state.callStatus = []
            notification.error({
                message: action?.payload?.response?.data?.message || "ERROR",
            })
        })

    builder
        .addCase(callGetContactEventStatus.pending, (state) => {
            state.contactEventStatus = []
        })
        .addCase(callGetContactEventStatus.fulfilled, (state, { payload }) => {
            state.contactEventStatus = payload.data
        })
        .addCase(callGetContactEventStatus.rejected, (state) => {
            state.contactEventStatus = []
        })

    builder
        .addCase(callGetOrderStatus.pending, (state) => {
            state.orderStatuses = []
        })
        .addCase(callGetOrderStatus.fulfilled, (state, { payload }) => {
            state.orderStatuses = payload.data
        })
        .addCase(callGetOrderStatus.rejected, (state) => {
            state.orderStatuses = []
        })

    builder
        .addCase(callGetSubOrderStatus.pending, (state) => {
            state.subOrderStatuses = []
        })
        .addCase(callGetSubOrderStatus.fulfilled, (state, { payload }) => {
            state.subOrderStatuses = payload.data
        })
        .addCase(callGetSubOrderStatus.rejected, (state) => {
            state.subOrderStatuses = []
        })
    builder
        .addCase(callGetDeliveryCaseStatus.pending, (state) => {
            state.deliveryCaseStatus = []
        })
        .addCase(callGetDeliveryCaseStatus.fulfilled, (state, { payload }) => {
            state.deliveryCaseStatus = payload.data
        })
        .addCase(callGetDeliveryCaseStatus.rejected, (state) => {
            state.deliveryCaseStatus = []
        })
    builder
        .addCase(callGetDeliveryCaseReason.pending, (state) => {
            state.deliveryCaseReason = []
        })
        .addCase(callGetDeliveryCaseReason.fulfilled, (state, { payload }) => {
            state.deliveryCaseReason = payload.data
        })
        .addCase(callGetDeliveryCaseReason.rejected, (state) => {
            state.deliveryCaseReason = []
        })
    builder
        .addCase(callGetComboCategories.pending, (state) => {
            state.comboCategories = []
        })
        .addCase(callGetComboCategories.fulfilled, (state, { payload }) => {
            state.comboCategories = payload.data
        })
        .addCase(callGetComboCategories.rejected, (state) => {
            state.comboCategories = []
        })
})
