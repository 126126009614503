import React, { PropsWithChildren, useEffect } from "react"
import { Button, Card, Col, InputNumber, message, Popover, Row, Table, Typography } from "antd"
import { trans } from "@resources/localization"
import { convertNumberToCurrency } from "@util/Common"
import { useAppDispatch } from "@hook/useAppDispatch"
import { changeDataOrderUpdate, changeDataUpdateOrderItem, fetchUpdateOrderItem, getOrderDetail } from "@reducer/version2/order-detail.reducer"
import { useAppSelector } from "@hook/useAppSelector"
import { find, first, isEmpty } from "lodash"
import SecurityService from "@util/SecurityService"
import { ORDER_SCOPE } from "@config/permission"
import { ICombo, ISkuCombo, ItemOrder, ItemOrderRequest } from "@domain/version2/OrderDetail"
import { ColumnsType } from "antd/lib/table"
import { LIST_CURRENCY, STATUS_CAN_UPDATE } from "@config/constant"

interface Props extends PropsWithChildren<any> {
    toggleFullSizeOrderItem: () => any
    onUpdateOrder: (key: string, value: any) => void
}

export const SkuCombo = (props: Props) => {
    const dispatch = useAppDispatch()
    const { onUpdateOrder } = props
    const orderDetail = useAppSelector((state) => state.orderDetailVersion2.orderDetail)
    const loadingUpdateOrderItem = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrderItem)
    const dataNeedChange = useAppSelector((state) => state.orderDetailVersion2.dataUpdateOrderItem)
    const dataUpdateOrder = useAppSelector((state) => state.orderDetailVersion2.dataOrderUpdate)
    const loadingUpdateOrder = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrder)
    useEffect(() => {
        if (!isEmpty(orderDetail?.combos)) {
            const dataNeedChange: ItemOrderRequest[] =
                orderDetail?.combos?.map((item: ICombo) => ({
                    sku: item?.combo,
                    price: item?.price,
                    quantity: item?.quantity,
                })) || []
            dispatch(changeDataUpdateOrderItem(dataNeedChange || []))
        }
    }, [orderDetail])
    const onChangeDetailOrder = (index: number) => {
        const dataCurrentChange = dataNeedChange[index]
        if (dataCurrentChange?.price && dataCurrentChange?.quantity) {
            dispatch(
                fetchUpdateOrderItem({
                    code: orderDetail?.code || "",
                    body: {
                        orderItems: [],
                        orderCombos: dataNeedChange,
                    },
                })
            ).then((result) => {
                if (result?.payload?.status === 200) {
                    message.success(trans("message.success"))
                    dispatch(getOrderDetail(orderDetail?.code || ""))
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }
    const columns: ColumnsType<any> = [
        {
            title: trans("skuCombo.title"),
            render: () => (
                <Popover
                    content={
                        <>
                            {first(orderDetail?.combos)?.skus.map((item: ISkuCombo) => (
                                <ul key={item.id}>
                                    <li className="ml-3 list-disc">{item?.product?.name || ""}</li>
                                    <li className="ml-3 list-none">
                                        {item?.variant ? Object?.values(item?.variant)?.join("/") : ""}({item?.sku || ""})
                                    </li>
                                    <li className="ml-3 list-none">
                                        {item?.quantity || 0} {trans("campaign.items")}
                                    </li>
                                </ul>
                            ))}
                        </>
                    }>
                    <p className="m-0 cursor-pointer hover:text-blue-400">
                    {first(orderDetail?.pricePolicies)?.comboName || ""} - {first(orderDetail?.pricePolicies)?.sku || ""}
                    </p>
                </Popover>
            ),
        },
        {
            title: () => <p className="m-0">{trans("order_detail.quantity")}</p>,
            dataIndex: "quantity",
            key: "quantity",
            width: "25%",
            render: (quantity: number, record: ItemOrder, index: number) => (
                <>
                    <InputNumber
                        min={0}
                        width="w-full"
                        value={dataNeedChange[index]?.quantity}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        disabled={
                            !SecurityService.can(ORDER_SCOPE.ORDER_UPDATE_ORDER_ITEM) ||
                            !STATUS_CAN_UPDATE.includes(orderDetail?.status || "") ||
                            loadingUpdateOrderItem
                        }
                        onChange={(e) => {
                            const newChangeArr: ItemOrderRequest[] = dataNeedChange?.slice()
                            newChangeArr[index] = {
                                ...dataNeedChange[index],
                                quantity: Number(e),
                            }
                            dispatch(changeDataUpdateOrderItem(newChangeArr))
                        }}
                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                        onPressEnter={() => onChangeDetailOrder(index)}
                    />
                    <Typography.Text type="danger">{!dataNeedChange[index]?.quantity && trans("message.required")}</Typography.Text>
                </>
            ),
        },
        {
            title: () => (
                <>
                    <p className="m-0">{trans("order_detail.price")}</p>
                    {orderDetail?.countryCode && <p className="m-0">{`(${find(LIST_CURRENCY, { value: orderDetail?.countryCode })?.code})`}</p>}
                </>
            ),
            dataIndex: "price",
            key: "price",
            width: "25%",
            render: (price: number, record: ItemOrder, index: number) => (
                <>
                    <InputNumber
                        min={0}
                        width="w-full"
                        value={dataNeedChange[index]?.price}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        disabled={
                            !SecurityService.can(ORDER_SCOPE.ORDER_UPDATE_ORDER_ITEM) ||
                            !STATUS_CAN_UPDATE.includes(orderDetail?.status || "") ||
                            loadingUpdateOrderItem
                        }
                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                        onChange={(e) => {
                            const newChangeArr: ItemOrderRequest[] = dataNeedChange?.slice() || []
                            newChangeArr[index] = {
                                ...dataNeedChange[index],
                                price: Number(e),
                            }
                            dispatch(changeDataUpdateOrderItem(newChangeArr))
                        }}
                        onPressEnter={() => onChangeDetailOrder(index)}
                    />
                    <Typography.Text type="danger">{!dataNeedChange[index]?.price && trans("message.required")}</Typography.Text>
                </>
            ),
        },
        {
            title: () => (
                <>
                    <p className="m-0">{trans("order_detail.total")}</p>
                    {/* {orderDetail?.country?.currencyUnit && <p className="m-0">({orderDetail?.country?.currencyUnit})</p>} */}
                    {orderDetail?.countryCode && <p className="m-0">{`(${find(LIST_CURRENCY, { value: orderDetail?.countryCode })?.code})`}</p>}
                </>
            ),
            dataIndex: "totalAmount",
            key: "totalAmount",
            width: "25%",
            render: (totalAmount: number, record: ItemOrder, index: number) =>
                dataNeedChange[index]?.price && dataNeedChange[index]?.quantity
                    ? convertNumberToCurrency(Number(dataNeedChange[index]?.price) * Number(dataNeedChange[index]?.quantity))
                    : "---",
        },
        {
            title: () => (
                <Button
                    type={"link"}
                    icon={
                        <i
                            className="fa fa-arrows-alt"
                            aria-hidden="true"
                        />
                    }
                    onClick={props.toggleFullSizeOrderItem}
                />
            ),
            dataIndex: "action",
            key: "action",
            width: 60,
        },
    ]
    const renderFooter = () => {
        return (
            <Row
                justify="end"
                gutter={[0, 15]}>
                <Col
                    span={12}
                    offset={12}
                    className="flex">
                    <Typography.Text className="w-60 mr-1">
                        {trans("order_detail.total_amount")}{" "}
                        {orderDetail?.country?.currencyUnit && <span>{`(${find(LIST_CURRENCY, { value: orderDetail?.countryCode })?.code})`}</span>}
                        {/*  <span>({orderDetail?.country?.currencyUnit})</span>} */}
                    </Typography.Text>
                    <Typography.Text className="w-full">{orderDetail?.totalAmount ? convertNumberToCurrency(orderDetail?.totalAmount) : "---"}</Typography.Text>
                </Col>
                <Col
                    span={12}
                    offset={12}
                    className="flex">
                    <Typography.Text className="w-60 mr-1">
                        {trans("order.delivery_fee")}
                        {orderDetail?.country?.currencyUnit && <span>{`(${find(LIST_CURRENCY, { value: orderDetail?.countryCode })?.code})`}</span>}
                        {/* {orderDetail?.country?.currencyUnit && <span>({orderDetail?.country?.currencyUnit})</span>} */}
                    </Typography.Text>
                    <InputNumber
                        min={0}
                        width="w-full"
                        value={dataUpdateOrder?.deliveryFee}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) => {
                            dispatch(
                                changeDataOrderUpdate({
                                    deliveryFee: Number(e),
                                })
                            )
                        }}
                        onPressEnter={() => onUpdateOrder("deliveryFee", "")}
                        disabled={
                            !SecurityService.can(ORDER_SCOPE.ORDER_UPDATE_DELIVERY_FEE) ||
                            !STATUS_CAN_UPDATE.includes(orderDetail?.status || "") ||
                            loadingUpdateOrder
                        }
                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                    />
                </Col>
                <Col
                    span={12}
                    offset={12}
                    className="flex">
                    <Typography.Text className="w-60 mr-1">
                        {trans("order_detail.total_payment")}
                        {orderDetail?.country?.currencyUnit && <span>{`(${find(LIST_CURRENCY, { value: orderDetail?.countryCode })?.code})`}</span>}
                        {/* {orderDetail?.country?.currencyUnit && <span>({orderDetail?.country?.currencyUnit})</span>} */}
                    </Typography.Text>
                    <InputNumber
                        min={0}
                        width="w-full"
                        value={dataUpdateOrder?.totalPayment}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        parser={(value: any) => value?.toString()?.replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "")}
                        onChange={(e) => {
                            dispatch(
                                changeDataOrderUpdate({
                                    totalPayment: Number(e),
                                })
                            )
                        }}
                        onPressEnter={() => onUpdateOrder("totalPayment", "")}
                        disabled={
                            !SecurityService.can(ORDER_SCOPE.ORDER_UPDATE_TOTAL) || !STATUS_CAN_UPDATE.includes(orderDetail?.status || "") || loadingUpdateOrder
                        }
                    />
                </Col>
            </Row>
        )
    }
    return (
        <div className="mt-3">
            <Card title={trans("order.product_detail")}>
                <Table
                    columns={columns}
                    dataSource={orderDetail?.combos || []}
                    rowKey={"id"}
                    loading={loadingUpdateOrderItem}
                    pagination={false}
                    locale={{
                        emptyText: trans("table.empty"),
                    }}
                    footer={() => renderFooter()}
                />
            </Card>
        </div>
    )
}
