import { ICallStatus, IComboCategories, IContactStatus, IDeliveryCaseReason, IDeliveryCaseStatus, IEventStatus } from "@domain/version2/Categories"
import { IOrderStatus } from "@domain/version2/OrderStatus"
import apiClient from "@util/ApiClient"
import { AxiosResponse } from "axios"

export default class CategoriesApi {
    static getContactStatus(): Promise<AxiosResponse<IContactStatus[]>> {
        return apiClient.get("categories/contact-statuses")
    }

    static getCallStatus(): Promise<AxiosResponse<ICallStatus[]>> {
        return apiClient.get("categories/call-statuses")
    }

    static getContactStatusEvent(): Promise<AxiosResponse<IEventStatus[]>> {
        return apiClient.get("categories/contact-event-statuses")
    }

    static getOrderStatus(): Promise<AxiosResponse<IOrderStatus[]>> {
        return apiClient.get(`categories/order-statuses`)
    }

    static getSubOrderStatus(parentCode: string): Promise<AxiosResponse<IOrderStatus[]>> {
        return apiClient.get(`categories/order-status-by-parent/${parentCode}`)
    }

    static getDeliveryCaseStatus(): Promise<AxiosResponse<IDeliveryCaseStatus[]>> {
        return apiClient.get("categories/delivery-case-statuses")
    }
    static getDeliveryCaseReason(): Promise<AxiosResponse<IDeliveryCaseReason[]>> {
        return apiClient.get("categories/delivery-case-reasons")
    }
    static getComboCategories(): Promise<AxiosResponse<IComboCategories[]>> {
        return apiClient.get("categories/combo-categories")
    }
}
