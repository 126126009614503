import React, { useEffect } from "react"

import { Button, Col, Form, Input, message, Modal, Row, Space } from "antd"

import { useAppSelector } from "@hook/useAppSelector"
import { useAppDispatch } from "@hook/useAppDispatch"
import { trans } from "@resources/localization"
import { callGetCallStatus } from "@reducer/version2/categories.reducer"
import { GeneralForm } from "./GeneralCreate"
import { AdsCreate } from "./AdsCreate"
import { ReasonAndOther } from "./ReasonAndOther"
import { AppointmentDate } from "./AppointmentDate"
import { AddressCreate } from "./AddressCreate"
import { PricePolicy } from "./PricePolicy"
import lodash, { find, first, isEmpty } from "lodash"
import {
    callCreateContactEvent,
    callGetContactAds,
    callGetContactDetail,
    callGetContactEvents,
    callGetListOrdersOfContactInPeriod,
    changeAddEventFormVisible,
} from "@reducer/version2/contact-detail.reducer"
import { getCountriesSuggest, getCourierSuggest, getProvinceSuggest, resetDataArea, resetDataDistrict } from "@reducer/version2/countries.reducer"
import { TablePricePolicy } from "../TablePricePolicy"
import { IPricePolicy } from "@domain/version2/ContactDetail"
import moment from "moment"
import { LIST_CURRENCY } from "@config/constant"
import { AddListSkuCombo } from "./AddListSkuCombo"
import { ListComboSku } from "../ListComboSku"

export const FormAddEvent = () => {
    const [formRef] = Form.useForm()
    const dispatch = useAppDispatch()
    const isShowAddEventForm = useAppSelector((state) => state.contactDetailVersion2.isShowAddEventForm)
    const detailContact = useAppSelector((state) => state.contactDetailVersion2.contactDetail)
    const listAds = useAppSelector((state) => state.contactDetailVersion2.listContactAds)
    const loadingAddEvent = useAppSelector((state) => state.contactDetailVersion2.loadingCreateEvent)
    const adSkus = useAppSelector((state) => state.contactDetailVersion2.adSkus)

    useEffect(() => {
        dispatch(callGetCallStatus())
        dispatch(getCountriesSuggest())
    }, [dispatch])

    useEffect(() => {
        if (!isEmpty(detailContact)) {
            dispatch(callGetContactAds(detailContact?.id || ""))
            dispatch(
                getCourierSuggest({
                    vendorCode: detailContact?.vendor,
                })
            )
            dispatch(getProvinceSuggest(detailContact?.country?.code || ""))
        }
    }, [detailContact])
    const onCreateContactEvent = (values: any) => {
        const dataRequest: any = {
            status: values?.status || undefined,
            note: values?.noteOther || values?.note,
            scheduleDate: values?.scheduleDate ? moment(values?.scheduleDate).toISOString() : undefined,
            ad: values?.ads || undefined,
            end: values?.end || false,
            reason: values?.reason || undefined,
            ...(["reservation", "agree"]?.includes(values?.status) && {
                orderData: {
                    address: values?.address || undefined,
                    note: values?.note || undefined,
                    postalCode: values?.postalCode || undefined,
                    items: !isEmpty(values?.pricePolicies)
                        ? values?.pricePolicies?.map((item: any) => ({
                              sku: item?.property,
                              price: Number(item?.unitPrice),
                              quantity: Number(item?.quantity),
                          }))
                        : [],
                    crossOrderItems: adSkus?.map((x: any) => {
                        return {
                            sku: lodash.get(x, "sku.sku"),
                            price: lodash.get(x, "price"),
                            quantity: lodash.get(x, "quantity"),
                        }
                    }),
                    combos:
                        values?.combos?.map((item: any) => ({
                            sku: item?.sku,
                            price: item?.unitPrice,
                            quantity: item?.quantity,
                        })) || [],
                    country: detailContact?.country?.code || undefined,
                    province: values?.provinceCode || undefined,
                    city: values?.cityCode || undefined,
                    district: values?.districtCode || undefined,
                    ward: values?.wardCode || undefined,
                    courierService: values?.courier || undefined,
                    deliveryDate: values?.deliveryDate ? moment(values?.deliveryDate).toISOString() : undefined,
                    deliveryFee: Number(values?.deliveryFee) || undefined,
                    totalPayment: Number(values?.totalPayment) || undefined,
                },
            }),
        }
        dispatch(
            callCreateContactEvent({
                id: detailContact?.id || "",
                body: dataRequest,
            })
        ).then((result: any) => {
            if (result?.payload?.status === 201) {
                message.success(trans("message.success"))
                formRef.resetFields()
                dispatch(resetDataDistrict([]))
                dispatch(resetDataArea([]))
                dispatch(callGetContactDetail(detailContact?.id || ""))
                dispatch(callGetContactEvents(detailContact?.id || ""))
                dispatch(callGetListOrdersOfContactInPeriod(detailContact?.id || ""))
                dispatch(changeAddEventFormVisible(false))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }

    return (
        <Modal
            width={860}
            title={trans("contactDetail.addEventModalTitle")}
            open={isShowAddEventForm}
            onCancel={() => {
                formRef.resetFields()
                dispatch(resetDataDistrict([]))
                dispatch(resetDataArea([]))
                dispatch(changeAddEventFormVisible(false))
            }}
            footer={null}
            destroyOnClose={true}>
            <Form
                layout="vertical"
                form={formRef}
                onFinish={onCreateContactEvent}>
                <GeneralForm formRef={formRef} />
                <Form.Item
                    noStyle
                    shouldUpdate={(pre, next) => pre.status !== next.status}>
                    {({ getFieldValue }) => {
                        const currentStatus = getFieldValue("status")
                        return (
                            <>
                                {["declined", "consider", "agree", "reservation"].indexOf(currentStatus) >= 0 && (
                                    <>
                                        <AdsCreate formRef={formRef} />
                                        {["declined"].indexOf(currentStatus) >= 0 && <ReasonAndOther />}
                                    </>
                                )}
                                {["consider", "not_answer", "answer_but_busy"].indexOf(currentStatus) >= 0 && <AppointmentDate />}
                                {!(["declined"].indexOf(currentStatus) >= 0) && (
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item
                                                label={trans("contact-detail.event_note")}
                                                name="note">
                                                <Input.TextArea
                                                    rows={4}
                                                    placeholder={trans("contact-detail.event_note")}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                                {["agree", "reservation"].indexOf(currentStatus) >= 0 && (
                                    <>
                                        <AddressCreate formRef={formRef} />
                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(pre, next) => pre.ads !== next.ads}>
                                            {({ getFieldValue }) => {
                                                const itemAds = find(listAds, { code: getFieldValue("ads") })
                                                console.log("itemAds", itemAds)
                                                const currentPrice: IPricePolicy[] = find(listAds, { code: getFieldValue("ads") })?.pricePolicies || []
                                                if (itemAds?.campaign?.productCode) {
                                                    return (
                                                        <>
                                                            <TablePricePolicy
                                                                currency={find(LIST_CURRENCY, { value: detailContact?.countryCode })?.code || ""}
                                                                // currency={detailContact?.country?.currency?.code || ""}
                                                                dataTable={currentPrice}
                                                            />
                                                            <PricePolicy
                                                                currency={find(LIST_CURRENCY, { value: detailContact?.countryCode })?.code || ""}
                                                                // currency={detailContact?.country?.currency?.code || ""}
                                                                listSkus={currentPrice}
                                                                form={formRef}
                                                            />
                                                        </>
                                                    )
                                                }
                                                if (itemAds?.campaign?.comboCode) {
                                                    return (
                                                        <>
                                                            <ListComboSku
                                                                currency={find(LIST_CURRENCY, { value: detailContact?.countryCode })?.code || ""}
                                                                // currency={detailContact?.country?.currency?.code || ""}
                                                                listPricePolicy={first(currentPrice)?.wholeSalePrice}
                                                            />
                                                            <AddListSkuCombo
                                                                currency={find(LIST_CURRENCY, { value: detailContact?.countryCode })?.code || ""}
                                                                // currency={detailContact?.country?.currency?.code || ""}
                                                            />
                                                        </>
                                                    )
                                                }
                                                return null
                                            }}
                                        </Form.Item>
                                    </>
                                )}
                            </>
                        )
                    }}
                </Form.Item>
                <Row
                    justify="end"
                    className="mt-3">
                    <Space>
                        <Button
                            type="default"
                            loading={loadingAddEvent}
                            onClick={() => {
                                formRef.resetFields()
                                dispatch(resetDataDistrict([]))
                                dispatch(resetDataArea([]))
                                dispatch(changeAddEventFormVisible(false))
                            }}>
                            {trans("setting.cancel")}
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadingAddEvent}>
                            {trans("setting.save")}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </Modal>
    )
}
