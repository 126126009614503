import React from "react"
import { Card, Checkbox, Col, message, Row, Select } from "antd"
import { trans } from "@resources/localization"
import { useAppSelector } from "@hook/useAppSelector"
import SecurityService from "@util/SecurityService"
import { ORDER_SCOPE } from "@config/permission"
import { useAppDispatch } from "@hook/useAppDispatch"
import { callUpdateOrderFinancialStatus, changeVisibleFormReasonCancel, fetchUpdateOrderPaid, fetchUpdateOrderStatus, getOrderDetail } from "@reducer/version2/order-detail.reducer"
import { ORDER_STATUS, STATUS_CAN_UPDATE } from "@config/constant"
import { callGetSubOrderStatus } from "@reducer/version2/categories.reducer"
import { formatDateFull } from "@util/Common"


export const OrderDetailHeader: React.FC= () => {
    const dispatch = useAppDispatch()
    const detailOrder = useAppSelector((state) => state.orderDetailVersion2.orderDetail)
    const loadingUpdateStatus = useAppSelector((state) => state.orderDetailVersion2.loadingUpdateOrderStatus)
    const loadingUpdatePaid = useAppSelector((state) => state.orderDetailVersion2.loadingUpdatePaid)
    const orderStatuses = useAppSelector((state) => state.categoriesVersion2.orderStatuses)
    const listFinancialStatus = useAppSelector(state => state.financialStatus.listFinancialStatus)

    const onChangeStatus = (status: string) => {
        if (status === ORDER_STATUS.CANCELLED) {
            dispatch(callGetSubOrderStatus(status))
            dispatch(changeVisibleFormReasonCancel(true))
        } else {
            dispatch(
                fetchUpdateOrderStatus({
                    code: detailOrder?.code || "",
                    body: {
                        status: status || "",
                    },
                })
            ).then((result: any) => {
                if (result?.payload?.status === 200) {
                    message.success(trans("message.success"))
                    dispatch(getOrderDetail(detailOrder?.code || ""))
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }

    const onUpdatePaid = (isPaid: boolean) => {
        dispatch(
            fetchUpdateOrderPaid({
                code: detailOrder?.code || "",
                body: {
                    isPaid,
                },
            })
        ).then((result: any) => {
            if (result?.payload?.status === 200) {
                message.success(trans("message.success"))
                dispatch(getOrderDetail(detailOrder?.code || ""))
            } else {
                message.error(result?.payload?.response?.data?.message || trans("message.fail"))
            }
        })
    }
    const onChangeFinancialStatus = (financial: string) => {
        if (financial) {
            dispatch(
                callUpdateOrderFinancialStatus({
                    orderCode: detailOrder?.code || "",
                    financial,
                })
            ).then((result: any) => {
                if (result?.payload?.status === 200) {
                    message.success(trans("message.success"))
                    dispatch(getOrderDetail(detailOrder?.code || ""))
                } else {
                    message.error(result?.payload?.response?.data?.message || trans("message.fail"))
                }
            })
        }
    }
    return (
        <Card className="space-layout">
            <Row gutter={[15, 15]}>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="m-0">{trans("order.code")}</p>
                    <div className="font-medium">{detailOrder?.code}</div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="m-0">{trans("order.campaign")}</p>
                    <div className="font-medium">{detailOrder?.campaign?.name || ""}</div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="m-0">{trans("order_detail.ads")}</p>
                    <div className="font-medium">{detailOrder?.ads?.name || ""}</div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="m-0">{trans("order_detail.due_date")}</p>
                    <div className="font-medium">{detailOrder?.deliveryDate ? formatDateFull(detailOrder?.deliveryDate) : "---"}</div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="m-0">{trans("order_detail.status")}</p>
                    {SecurityService.can(ORDER_SCOPE.ORDER_UPDATE_STATUS) && STATUS_CAN_UPDATE.includes(detailOrder?.status || "") ? (
                        <Select
                            loading={loadingUpdateStatus}
                            value={detailOrder?.status}
                            className="block w-11/12"
                            onChange={(e) => {
                                onChangeStatus(e)
                            }}>
                            {orderStatuses.map((x, index: number) => (
                                <Select.Option
                                    key={x.code}
                                    value={x.code}
                                    disabled={
                                        index < orderStatuses.findIndex((x: any) => x.code === detailOrder?.status) ||
                                        ![...STATUS_CAN_UPDATE, "CANCELLED"].includes(x.code)
                                    }>
                                    {" "}
                                    {trans(`order_status.${x?.code}`)}
                                </Select.Option>
                            ))}
                        </Select>
                    ) : (
                        <div className="font-medium">{trans(`order_status.${detailOrder?.status}`)}</div>
                    )}
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="m-0">{trans("order_detail.created_time")}</p>
                    <div className="font-medium">{detailOrder?.createdAt ? formatDateFull(detailOrder?.createdAt) : ""}</div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="m-0">{trans("order_detail.created_by")}</p>
                    <div className="font-medium">{detailOrder?.createdBy}</div>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <Checkbox
                        onChange={(e) => {
                            onUpdatePaid(e.target.checked)
                        }}
                        disabled={
                            !(SecurityService.can(ORDER_SCOPE.ORDER_UPDATE_PAID) && STATUS_CAN_UPDATE.includes(detailOrder?.status || "")) ||
                            loadingUpdatePaid
                        }
                        className="mt-3"
                        checked={detailOrder?.isPaid}>
                        {trans("order_detail.paid")}
                    </Checkbox>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                        <p className="m-0">{trans("order_detail.financial_status")}</p>
                        {SecurityService.can(ORDER_SCOPE.ORDER_FINANCIAL_STATUS_UPDATE) ? (
                            <Select
                                value={detailOrder?.financial}
                                className="block w-11/12"
                                onChange={(e) => {
                                    onChangeFinancialStatus(e)
                                }}>
                                {listFinancialStatus.map((item) => (
                                    <Select.Option
                                        key={item.code}
                                        value={item.code}>
                                        {trans(`order_detail.financial_status_${item?.code}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <div className="font-medium">{trans(`order_detail.financial_status_${detailOrder?.financial}`)}</div>
                        )}
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}>
                    <p className="m-0">{trans("order_detail.tracking_number")}</p>
                    <div className="font-medium">{detailOrder?.trackingNumber || "---"}</div>
                </Col>
            </Row>
        </Card>
    )
}
