import { TelesalesApi } from "@api/version2/TelesalesApi"
import { TelesalesGroupApi } from "@api/version2/TelesalesGroupApi"
import { PAGINATION } from "@config/constant"
import { IGroupRequest, IGroupTelesales, IGroupTelesalesQuery, ILeader } from "@domain/version2/Telesales"
import { createAction, createAsyncThunk, createReducer } from "@reduxjs/toolkit"

interface State {
    pagination: {
        total: number
        currentPage: number
        totalPage: number
        pageSize: number
    }
    listGroup: IGroupTelesales[]
    loadingList: boolean
    listLeader: ILeader[]
    visibleModalAdd: boolean
    loadingCreate: boolean
    telesalesGroupsSuggest: IGroupTelesales[]
    loadingListGroupSuggest: boolean
}

const initState: State = {
    pagination: {
        total: 0,
        currentPage: 0,
        totalPage: 0,
        pageSize: 0,
    },
    listGroup: [],
    loadingList: false,
    listLeader: [],
    visibleModalAdd: false,
    loadingCreate: false,
    telesalesGroupsSuggest: [],
    loadingListGroupSuggest: false,
}

export const callGetListGroup = createAsyncThunk("TELESALES_GROUP.GET_LIST_GROUP", async (params: IGroupTelesalesQuery, thunkApi) => {
    try {
        return await TelesalesGroupApi.getListGroupTelesales(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetListLeaderSuggest = createAsyncThunk(
    "TELESALES_GROUP.GET_LIST_LEADER_SUGGEST",
    async (params: { username?: string; inSameGroupWith?: string }, thunkApi) => {
        try {
            return await TelesalesApi.getListLeaderSuggest(params)
        } catch (error) {
            return thunkApi.rejectWithValue(error)
        }
    }
)

export const callCreateGroupTelesales = createAsyncThunk("TELESALES_GROUP.CREATE_GROUP", async (payload: IGroupRequest, thunkApi) => {
    try {
        return await TelesalesGroupApi.createTelesalesGroup(payload)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const callGetTelesalesGroupsSuggest = createAsyncThunk("TELESALES_GROUP.GET_GROUPS_SUGGEST", async (params: {query?: string, hasTelesales?: string }, thunkApi) => {
    try {
        return await TelesalesGroupApi.getTelesalesGroupsSuggest(params)
    } catch (error) {
        return thunkApi.rejectWithValue(error)
    }
})

export const changeModalAddGroup = createAction<boolean>("TELESALES_GROUP.CHANGE_MODAL_GROUP")

export const telesalesGroupReducer = createReducer(initState, (builder) => {
    builder
        .addCase(callGetListGroup.pending, (state) => {
            state.listGroup = []
            state.loadingList = true
        })
        .addCase(callGetListGroup.rejected, (state) => {
            state.listGroup = []
            state.loadingList = false
        })
        .addCase(callGetListGroup.fulfilled, (state, { payload }) => {
            state.listGroup = payload.data
            state.pagination.currentPage = payload.headers["x-page-number"] ? Number(payload.headers["x-page-number"]) : PAGINATION.DEFAULT_CURRENT_PAGE
            state.pagination.pageSize = payload.headers["x-page-size"] ? Number(payload.headers["x-page-size"]) : PAGINATION.DEFAULT_PAGE_SIZE
            state.pagination.total = payload.headers["x-total-count"] ? Number(payload.headers["x-total-count"]) : PAGINATION.DEFAULT_TOTAL_ITEM
            state.pagination.totalPage = payload.headers["x-page-count"] ? Number(payload.headers["x-page-count"]) : PAGINATION.DEFAULT_TOTAL_PAGE
            state.loadingList = false
        })

    builder.addCase(changeModalAddGroup, (state, { payload }) => {
        state.visibleModalAdd = payload
    })
    builder
        .addCase(callGetListLeaderSuggest.pending, (state) => {
            state.listLeader = []
        })
        .addCase(callGetListLeaderSuggest.rejected, (state) => {
            state.listLeader = []
        })
        .addCase(callGetListLeaderSuggest.fulfilled, (state, { payload }) => {
            state.listLeader = payload.data
        })
    builder
        .addCase(callCreateGroupTelesales.pending, (state) => {
            state.loadingCreate = true
        })
        .addCase(callCreateGroupTelesales.rejected, (state) => {
            state.loadingCreate = false
        })
        .addCase(callCreateGroupTelesales.fulfilled, (state) => {
            state.loadingCreate = false
        })
    builder
        .addCase(callGetTelesalesGroupsSuggest.pending, (state) => {
            state.loadingListGroupSuggest = true
            state.telesalesGroupsSuggest = []
        })
        .addCase(callGetTelesalesGroupsSuggest.rejected, (state) => {
            state.telesalesGroupsSuggest = []
            state.loadingListGroupSuggest = false
        })
        .addCase(callGetTelesalesGroupsSuggest.fulfilled, (state, { payload }) => {
            state.telesalesGroupsSuggest = payload.data
            state.loadingListGroupSuggest = false
        })
})
