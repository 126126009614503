import { FORMAT_DATE_BEGIN, FORMAT_DATE_END } from "@config/constant"
import { IProductGroup, IProductQuery } from "@domain/version2/Product"
import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd"
import { forEach, isEmpty, omitBy } from "lodash"
import moment from "moment"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { SearchOutlined } from "@ant-design/icons"

export const Filter: React.FC = () => {
    const [formRef] = Form.useForm()
    const listAllProduct = useAppSelector((state) => state.product.listAllProductGroup)
    const [searchParams, setSearchParams] = useSearchParams()
    const loadingList = useAppSelector((state) => state.product.loadingList)

    useEffect(() => {
        if (!isEmpty(Object.fromEntries(searchParams.entries()))) {
            const oldSearch = Object.fromEntries(searchParams.entries())
            formRef.setFieldsValue({
                ...oldSearch,
                ...(searchParams.has("createdAtFrom") &&
                    searchParams.has("createdAtTo") && {
                        createdAt: [moment(oldSearch?.createdAtFrom), moment(oldSearch?.createdAtTo)],
                    }),
                ...(searchParams.has("telesaleGroupCode") && {
                    telesaleGroupCode: oldSearch?.telesaleGroupCode?.split(","),
                }),
            })
        } else {
            formRef.resetFields()
        }
    }, [])

    const onFilter = (values: IProductQuery) => {
        const oldSearch = Object.fromEntries(searchParams.entries())
        setSearchParams(() => {
            let params: any = {
                ...oldSearch,
                ...values,
                telesaleGroupCode: values?.telesaleGroupCode ? values?.telesaleGroupCode?.join(",") : undefined,
                createdAtFrom: values?.createdAt ? moment(values?.createdAt[0].format(FORMAT_DATE_BEGIN)).toISOString() : undefined,
                createdAtTo: values?.createdAt ? moment(values?.createdAt[1].format(FORMAT_DATE_END)).toISOString() : undefined,
            }
            params.tab = "products"
            params = forEach(params, (v: any, k: string) => {
                if (typeof v === "string") v = v.trim()
                params[k] = v
            })
            params = omitBy(params, (v, k) => v === undefined || v === "" || k === "page")
            return params
        })
    }

    return (
        <Card className="space-layout">
            <Form
                form={formRef}
                onFinish={onFilter}
                layout="vertical">
                <Row gutter={12}>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("products.search_name_code")}
                            name="keyword">
                            <Input
                                placeholder={trans("products.search_name_code")}
                                suffix={<SearchOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("products.group")}
                            name="productGroup">
                            <Select placeholder={trans("products.select_group")}>
                                {listAllProduct?.map((iPro: IProductGroup) => (
                                    <Select.Option
                                        key={iPro.id}
                                        value={iPro.code}>
                                        {iPro.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                            label={trans("products.vendor_created_at")}
                            name="createdAt">
                            <DatePicker.RangePicker
                                format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                                placeholder={[trans("order_list.from"), trans("order_list.to")]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loadingList}
                            className="mg-r-10 _filter_button_filter">
                            {trans("button.filter")}
                        </Button>
                        <Button
                            type="default"
                            onClick={() => {
                                formRef.resetFields()
                                setSearchParams({})
                            }}
                            loading={loadingList}
                            className="mg-r-10 _filter_button_clear">
                            {trans("contact.clear")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}