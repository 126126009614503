import { useAppSelector } from "@hook/useAppSelector"
import { trans } from "@resources/localization"
import { Checkbox, Col, DatePicker, Form, Row, Select, Spin } from "antd"
import moment from "moment"
import React from "react"
type Props ={
    formRef: any
}
export const GeneralForm: React.FC<Props> = ({formRef}) => {
    const eventStatuses = useAppSelector((state) => state.categoriesVersion2.callStatus)
    return (
        <Row>
            <Col span={24}>
                <Form.Item label={trans("contact-detail.date_time")}>
                    <DatePicker
                        disabledDate={(current) => current < moment().startOf("day")}
                        placeholder={trans("contact-detail.appointment_pl")}
                        className={"_appointment w-full"}
                        format={"DD/MM/YYYY h:mm A"}
                        showTime={{ defaultValue: moment(new Date(), "HH:mm:ss") }}
                        defaultValue={moment()}
                        disabled={true}
                    />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label={trans("contact-detail.status")}
                    name="status"
                    rules={[
                        {
                            required: true,
                            message: trans("message.required")
                        },
                    ]}>
                    <Select
                        placeholder={trans('contact-detail.validate_status')}
                        onChange={() =>{
                            formRef.setFieldsValue({
                                ads: null,
                                note: '',
                                scheduleDate: '',
                                address: '',
                                pricePolicies: [],
                                provinceCode: null,
                                cityCode: null,
                                districtCode: null,
                                wardCode: null,
                                courier: null,
                                deliveryDate: '',
                                deliveryFee: '',
                                totalPayment: '',
                            })
                        }}
                        showSearch
                        notFoundContent={<Spin />}>
                        {eventStatuses.map((item) => (
                            <Select.Option
                                key={item.id}
                                value={item.code}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24} className="m-0">
                <Form.Item
                    label=''
                    className="m-0"
                    name="end"
                    valuePropName="checked"
                    >
                        <Checkbox>{trans('contact-detail.keep')}</Checkbox>
                </Form.Item>
            </Col>
        </Row>
    )
}
