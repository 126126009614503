import React, { PropsWithChildren, useEffect, useState } from "react"

import { Col, Row } from "antd"
import { Product } from "./Product"
import { ShippingAddress } from "./ShippingAddress"
import { Note } from "./Note"
import { useAppSelector } from "@hook/useAppSelector"
import { SkuCombo } from "./SkuCombo"
// import { Shipping } from "./Shipping";
// import { WarehouseNote } from "./WarehouseNote";

interface IColInfo {
    xs: number
    lg: number
    xl: number
}

interface Props extends PropsWithChildren<any> {
    onUpdateOrder: (key: string, value: any) => void
}

export const Summary = (props: Props) => {
    const { onUpdateOrder } = props
    const [isFullSizeOrderItem, setIsFullSizeOrderItem] = useState<boolean>(false)
    const [colLeft, setColLeft] = useState<IColInfo>({ xs: 24, lg: 13, xl: 15 })
    const [colRight, setColRight] = useState<IColInfo>({ xs: 24, lg: 11, xl: 9 })
    const detailOrder = useAppSelector((state) => state.orderDetailVersion2.orderDetail)

    useEffect(() => {
        setColLeft({
            xs: 24,
            lg: isFullSizeOrderItem ? 24 : 13,
            xl: isFullSizeOrderItem ? 24 : 15,
        })

        setColRight({
            xs: 24,
            lg: isFullSizeOrderItem ? 24 : 11,
            xl: isFullSizeOrderItem ? 24 : 9,
        })
    }, [isFullSizeOrderItem])

    const toggleFullSizeOrderItem = () => {
        setIsFullSizeOrderItem(!isFullSizeOrderItem)
    }
    return (
        <>
            <Row gutter={12}>
                <Col {...colLeft}>
                    {detailOrder?.campaign?.comboCode ? (
                        <SkuCombo
                            toggleFullSizeOrderItem={toggleFullSizeOrderItem}
                            onUpdateOrder={onUpdateOrder}
                        />
                    ) : (
                        <Product
                            toggleFullSizeOrderItem={toggleFullSizeOrderItem}
                            onUpdateOrder={onUpdateOrder}
                        />
                    )}
                </Col>
                <Col {...colRight}>
                    <ShippingAddress />

                    <Note onUpdateOrder={onUpdateOrder} />
                </Col>
            </Row>
        </>
    )
}
