export const CONTACT_SCOPE = {
    CONTACT_GET: "contact:getcontact",
    CONTACT_LIST_VIEW: "contact:list:view",
    CONTACT_LIST_GROUP_VIEW: "contact:list:groupview",
    CONTACT_LIST_VIEW_ALL: "contact:list:viewall",
    CONTACT_DETAIL_VIEW: "contact:detail:view",
    CONTACT_DETAIL_GROUP_VIEW: "contact:detail:groupview",
    CONTACT_DETAIL_VIEW_ALL: "contact:detail:viewall",
    CONTACT_DETAIL_UPDATE: "contact:detail:update",
    CONTACT_ADD_EVENT: "contact:event:create",
    CONTACT_CALL_STRINGEE: "contact:clicktocall",
    CONTACT_CALL_HISTORY: "contact:callhistory",
    CONTACT_ACTIVITY_HISTORY: "contact:activities_history:view",
    CONTACT_ASSIGN_RESET: "contact:assignee:reset",
    CONTACT_ASSIGN_TO:'contact:detail:assign',
    CONTACT_DETAIL_GROUPVIEW:'contact:detail:groupview',
}

export const ORDER_SCOPE = {
    ORDER_LIST_VIEW: "order:list:view",
    ORDER_LIST_VIEW_ALL: "order:list:viewall",
    ORDER_DETAIL_VIEW: "order:detail:view",
    ORDER_DETAIL_VIEW_ALL: "order:detail:viewall",
    ORDER_UPDATE_STATUS: "order:status:update",
    ORDER_UPDATE_PAID: "order:paid:update",
    ORDER_UPDATE_ADDRESS: "order:address:update",
    ORDER_UPDATE_NOTE: "order:note:update",
    ORDER_UPDATE_ORDER_ITEM: "order:order_item:update",
    ORDER_UPDATE_DELIVERY_FEE: "order:delivery_fee:update",
    ORDER_UPDATE_TOTAL: "order:total_payment:update",
    ORDER_DELIVERY_CASE_VIEW: "order:delivery_case:view",
    ORDER_FINANCIAL_STATUS_UPDATE: "order:financial_status:update",
}

export const CAMPAIGN_SCOPE = {
    CAMPAIGN_CREATE: "campaign:create",
    CAMPAIGN_IMPORT: "campaign:import",
    CAMPAIGN_UPDATE: "campaign:update",
    CAMPAIGN_VIEW: "campaign:view",
    CAMPAIGN_PRODUCT_CREATE: "campaign:product:create",
    CAMPAIGN_PRODUCT_DELETE: "campaign:product:delete",
    CAMPAIGN_PRODUCT_UPDATE: "campaign:product:update",
    CAMPAIGN_PRODUCT_VIEW: "campaign:product:view",
    CAMPAIGN_PRODUCT_ASSIGN: "campaign:product:assign",
    CAMPAIGN_DELETE: "campaign:update:delete",
    ADSET_DELETE: "campaign:update:delete_adset",
    AD_DELETE: "campaign:update:delete_ads",
}

export const GROUP_SCOPE = {
    GROUP_CREATE: "user:group:create",
    GROUP_UPDATE: "user:group:update",
    GROUP_DELETE: "user:group:delete",
    GROUP_VIEW: "user:group:view",
}

export const VENDOR_SCOPE = {
    VENDOR_CREATE: "vendor:create",
    VENDOR_ADD_USER: "vendor:user:add",
    VENDOR_SET_USER_ROLE: "vendor:user:setrole",
    VENDOR_VIEW: "vendor:view",
    VENDOR_UPDATE_ROLES: "vendor:setting:roles",
}

export const SETTING_SCOPE = {
    SETTING_ASSIGN: "system:config:get_contact",
    SETTING_VIEW: "system:config:view",
    SETTING_CANCEL_ASSIGNMENT_CREATE: "settings:cancel_assignment:create",
    SETTING_CANCEL_ASSIGNMENT_UPDATE: "settings:cancel_assignment:update",
    SETTING_CANCEL_ASSIGNMENT_VIEW: "settings:cancel_assignment:view",
    SETTING_SLA_CREATE: "settings:sla:create",
    SETTING_SLA_VIEW: "settings:sla:view",
    SETTING_SLA_UPDATE: "settings:sla:update",
}

export const PRODUCT_SCOPE = {
    PRODUCT_MANAGE: "management:product",
    PRODUCT_VIEW: "product:detail:view",
    PRODUCT_VIEW_ALL: "product:detail:viewall",
    PRODUCT_LIST_VIEW: "product:list:view",
    PRODUCT_LIST_VIEW_ALL: "product:list:viewall",
    PRODUCT_GROUP_UPDATE: "product:group:update",
    PRODUCT_GROUP_VIEW: "product:group:view",
}
export const TELESALES_COPE = {
    GROUP_VIEW_LIST: "group:list:view",
    GROUP_CREATE: "group:create",
    GROUP_UPDATE: "group:telesales:update",
    TELESALES_CREATE: "telesales:create",
    TELESALES_VIEW_LIST: "telesales:list:view",
    GROUP_DETAIL_VIEW: "group:detail:view",
    GROUP_TELESALES_UPDATE: "group:telesales:update",
    GROUP_PRODUCT_UPDATE: "group:product:update",
    GROUP_COMBO_UPDATE: "group:combo:update",
}

export const DELIVERY_CASE_SCOPE = {
    DELIVERY_CASE_IMPORT: "delivery_case:import",
    DELIVERY_CASE_VIEW: "delivery_case:list:view",
    DELIVERY_CASE_VIEW_ALL: "delivery_case:list:viewall",
    DELIVERY_CASE_ASSIGNEE: "delivery_case:assignee:update",
    DELIVERY_CASE_UPDATE: "delivery_case:update",
}

export const REPORT_SCOPE = {
    CALL_REPORT_VIEW: "report:call_status:view",
    CALL_REPORT_VIEW_ALL: "report:call_status:viewall",
    SLA_REPORT_VIEW: "report:sla:view",
    SLA_REPORT_VIEW_ALL: "report:sla:viewall",
    REPORT_SLA_UPDATE: "report:sla:update",
}

export const COMBO_SCOPE = {
    COMBO_LIST_VIEW: "combo:list:view",
    COMBO_LIST_VIEW_ALL: "combo:list:viewall",
    COMBO_DETAIL_VIEW: "combo:detail:view",
    COMBO_DETAIL_VIEW_ALL: "combo:detail:viewall",
    COMBO_GROUP_UPDATE: "combo:group:update",
    COMBO_GROUP_VIEW: "combo:group:view",
}
