import apiClient from "@util/ApiClient"
import { IOrderListResponse, IOrderQuery, IOrderStatus2 } from "@domain/version2/Order"
import { IDetailOrder, IOrderRequest, ItemOrderRequest, IUpdateOrderQuery } from "@domain/version2/OrderDetail"
import { IDeliveryCase } from "@domain/version2/DeliveryCase"
import { AxiosResponse } from "axios"

export class OrderApi {
    static getOrders(params: IOrderQuery): Promise<IOrderListResponse> {
        return apiClient.get(`orders`, { params })
    }
    static getOrderStatuses(): Promise<{ data: IOrderStatus2[] }> {
        return apiClient.get(`orders/statuses`)
    }

    static getOrderDetail(code: string): Promise<{ data: IDetailOrder }> {
        return apiClient.get(`orders/${code}`)
    }
    static updateOrder(code: string, body: IOrderRequest) {
        return apiClient.patch(`orders/${code}`, body)
    }
    static updateOrderItem(code: string, body: { orderItems: ItemOrderRequest[], orderCombos: ItemOrderRequest[] }): Promise<any> {
        return apiClient.put(`orders/${code}/items`, body)
    }
    static updateOrderStatus(code: string, body: IUpdateOrderQuery) {
        return apiClient.patch(`orders/${code}/status`, body)
    }
    static updateOrderPaid(code: string, body: { isPaid: boolean }) {
        return apiClient.patch(`orders/${code}/paid`, body)
    }
    static exportOrders(params: IOrderQuery) {
        return apiClient.post(`orders/export`, params)
    }
    static getListDeliveryCasesOfOrder(orderCode: string): Promise<AxiosResponse<IDeliveryCase[]>> {
        return apiClient.get(`orders/${orderCode}/delivery-cases`)
    }
    static updateOrderFinancialStatus(code: string, body: { financial: string }) {
        return apiClient.patch(`orders/${code}/financial-statuses`, body)
    }
    static importOrderFinancialStatus(file: File) {
        const formData: any = new FormData()
        formData.append("file", file)
        return apiClient.patch(`orders/financial-statuses`, formData)
    }
}
