export const CALL_OPERATIONS: Array<{ [key: string]: string }> = [
    { key: "contact.equal", value: "equals" },
    { key: "contact.more", value: "gte" },
    { key: "contact.less", value: "lte" },
    { key: "contact.between", value: "bt" },
]

export const PAGINATION: { [key: string]: any } = {
    DEFAULT_CURRENT_PAGE: 1,
    DEFAULT_PAGE_SIZE: 25,
    DEFAULT_TOTAL_ITEM: 0,
    DEFAULT_PAGE_SIZE_OPTIONS: ["25", "50", "100", "150", "200"],
    DEFAULT_TOTAL_PAGE: 1,
}

interface IBreadcrumb {
    name: string
    link?: string
}

export const HOME_BREADCRUMB: Array<IBreadcrumb> = [
    {
        name: "sidebar.dashboard",
        link: "/",
    },
]

export const CONTACT_DETAIL_BREADCRUMB: Array<IBreadcrumb> = [
    {
        name: "sidebar.dashboard",
        link: "/",
    },
    {
        name: "contact.page_title",
        link: "/contacts",
    },
]

export const CONTACT_BREADCRUMB: Array<IBreadcrumb> = [
    {
        name: "sidebar.dashboard",
        link: "/",
    },
    {
        name: "contact.title",
    },
]

export const ORDER_DETAIL_BREADCRUMB: Array<IBreadcrumb> = [
    {
        name: "sidebar.dashboard",
        link: "/",
    },
    {
        name: "order.page_title",
        link: "/orders",
    },
]

export const COUNTRY_PHILIPPINE_ID = process.env["REACT_APP_COUNTRY_PHILIPPINE_ID"]
export const COUNTRY_PHILIPPINE_CODE = process.env["REACT_APP_COUNTRY_PHILIPPINE_CODE"]

export const CONTACT_EVENT_REASON = [
    { key: "JUST_INQUIRING_ABOUT_THE_PRODUCT" },
    { key: "PRICE_IS_EXPENSIVE" },
    { key: "BUDGET_ISSUE" },
    { key: "NOT_INTERESTED" },
    { key: "MY_RELATIVE_IS_NOT_INTERESTED" },
    { key: "ALREADY_BOUGHT_TO_ANOTHER_SELLER" },
    { key: "ALREADY_BOUGHT_TO_ANOTHER_ITEM" },
    { key: "WANT_ANOTHER_COLOR_OR_SIZE" },
    { key: "CHANGED_MIND" },
    { key: "BOGUS_BUYER" },
    { key: "LESS_18_YEARS_OLD_CANT_AFFORD_TO_BUY" },
    { key: "OTHER" },
]

export const FORMAT_DATE_BEGIN = "YYYY-MM-DD 00:00:00"
export const FORMAT_DATE_END = "YYYY-MM-DD 23:59:59"
export const STATUS_CAN_UPDATE = ["DRAFT", "NEW", "WAREHOUSE_RECEIVED"]

export const LIST_CURRENCY = [
    { title: "₱", value: "PHL", type: "left", code: "PHP" },
    { title: "Rp", value: "IDN", type: "left", code: "IDR" },
    { title: "฿", value: "THA", type: "left", code: "THB" },
    { title: "₫", value: "VNM", type: "right", code: "VND" },
    { title: "RM", value: "MYS", type: "left", code: "MYR" },
    { title: "K", value: "MMR", type: "left", code: "MMK" },
]
export const VIETNAM_CODE = "VNM"

export const ORDER_STATUS = {
    CANCELLED: "CANCELLED",
}

export const CALL_STATUS = {
    INIT: 0,
    CALLING: 1,
    RINGING: 2,
    ANSWERED: 3,
    CONNECTED: 4,
    BUSY: 5,
    ENDED: 6 
}

export const STRINGEE_AUTH = {
    SUCCESS: 0,
    IS_AUTH: 1
}